import { useNavigate } from '@modern-js/runtime/router';
import { useEffect } from 'react';

function PageRedirect() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/writing', { replace: true });
  }, []);

  return null;
}

export default PageRedirect;
